var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "my-complaints" } },
    [
      _c("HeaderNavigation", { attrs: { title: "mes réclamations" } }),
      _c(
        "b-container",
        { staticClass: "main-container" },
        [
          _c(
            "b-row",
            { staticClass: "header" },
            [
              _c("b-col", { attrs: { cols: "4" } }, [_vm._v(" PERIODE ")]),
              _c("b-col", { attrs: { cols: "8" } }, [_vm._v(" INFORMATIONS ")])
            ],
            1
          ),
          _vm._l(_vm.complaints, function(complaint) {
            return _c(
              "b-row",
              { key: complaint.id, staticClass: "complaint text-uppercase" },
              [
                _c("b-col", { attrs: { cols: "4" } }, [
                  _c("span", { staticClass: "title-headline" }, [
                    _vm._v(_vm._s(complaint.period.date))
                  ]),
                  _c("p", { staticClass: "info" }, [
                    _vm._v(_vm._s(complaint.period.year))
                  ]),
                  _c("p", { staticClass: "info" }, [
                    _vm._v(
                      " " +
                        _vm._s(complaint.period.time.start) +
                        "-" +
                        _vm._s(complaint.period.time.start) +
                        " "
                    )
                  ])
                ]),
                _c(
                  "b-col",
                  { attrs: { cols: "8" } },
                  [
                    _c(
                      "span",
                      { staticClass: "title-headline text-uppercase" },
                      [
                        _vm._v(
                          "Point de recharge #" +
                            _vm._s(complaint.chargingPoint.id)
                        )
                      ]
                    ),
                    _c("p", { staticClass: "info" }, [
                      _vm._v("Point de recharge #8892")
                    ]),
                    _c("p", { staticClass: "info" }, [
                      _vm._v(_vm._s(complaint.chargingPoint.address))
                    ]),
                    _c(
                      "b-badge",
                      {
                        attrs: {
                          variant: _vm.badgeVariant(
                            complaint.chargingPoint.status
                          )
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.badgeStatus(complaint.chargingPoint.status)
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _vm.complaints.length == 0
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", [
                      _vm._v(
                        " vous n’avez actuellement aucune réclamation en cours auprès de nos services. Vous pouvez réaliser une réclamation lorsque vous utilisez un point de recharge ou que quelqu’un utilise votre point de recharge. "
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }