<template>
  <div id="my-complaints">
    <HeaderNavigation title="mes réclamations" />
    <b-container class="main-container">
      <b-row class="header">
        <b-col cols="4">
          PERIODE
        </b-col>
        <b-col cols="8">
          INFORMATIONS
        </b-col>
      </b-row>
      <b-row
        v-for="complaint in complaints"
        :key="complaint.id"
        class="complaint text-uppercase"
      >
        <b-col cols="4">
          <span class="title-headline">{{ complaint.period.date }}</span>
          <p class="info">{{ complaint.period.year }}</p>
          <p class="info">
            {{ complaint.period.time.start }}-{{ complaint.period.time.start }}
          </p>
        </b-col>
        <b-col cols="8">
          <span class="title-headline text-uppercase"
            >Point de recharge #{{ complaint.chargingPoint.id }}</span
          >
          <p class="info">Point de recharge #8892</p>
          <p class="info">{{ complaint.chargingPoint.address }}</p>
          <b-badge :variant="badgeVariant(complaint.chargingPoint.status)">{{
            badgeStatus(complaint.chargingPoint.status)
          }}</b-badge>
        </b-col>
      </b-row>
      <b-row v-if="complaints.length == 0">
        <b-col>
          <p>
            vous n’avez actuellement aucune réclamation en cours auprès de nos
            services. Vous pouvez réaliser une réclamation lorsque vous utilisez
            un point de recharge ou que quelqu’un utilise votre point de
            recharge.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'

export default {
  name: 'my-complaints',
  components: { HeaderNavigation },
  data() {
    return {
      complaints: []
    }
  },
  methods: {
    badgeVariant(status) {
      return status == 'pending' ? 'dark' : 'info'
    },
    badgeStatus(status) {
      return status == 'pending' ? 'EN COURS DE TRAITEMENT' : 'TRAITEE'
    }
  },
  async mounted() {
    this.complaints = await this.$apiService.getUserComplaintsAsync()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

#my-complaints {
  .header {
    padding-top: 40px;
    padding-bottom: 13px;
    background-color: #f8f8f8;
    color: #cecece;
    font-weight: $font-weight-bold;
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 0.65px;
  }

  .complaint {
    padding-top: 30px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ebebeb;

    .title-headline {
      font-weight: $font-weight-bold;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0;
      color: $dark;
    }

    .info {
      font-weight: $font-weight-bold;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0;
      color: #cecece;
      margin-bottom: 0;
    }

    .badge {
      border-radius: 6px;
      padding: 3px;
      padding-left: 5px;
      width: 118px;
      height: 17px;
      font-weight: $font-weight-bold;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0;
      text-align: left;
      color: #fff;

      &.badge-dark {
        background-color: $dark;
      }

      &.badge-info {
        background-color: #19e6d0;
      }
    }
  }
}
</style>
